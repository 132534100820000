/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	background: none;
	text-shadow: 0 1px rgba(0, 0, 0, 0.3);
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #272822;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #8292a2;
}

.token.punctuation {
	color: #f8f8f2;
}

.token.namespace {
	opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #f92672;
}

.token.boolean,
.token.number {
	color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
	color: #e6db74;
}

.token.keyword {
	color: #66d9ef;
}

.token.regex,
.token.important {
	color: #fd971f;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255,255,255,0.15), rgba(255,255,255,0));
  --bs-body-font-family: var(system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
}
:root,
[data-bs-theme=light] {
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33,37,41,0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33,37,41,0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0,0,0,0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var($bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0,0,0,0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0,0,0,0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13,110,253,0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
/*
  |--------------------------------------------------------------------------
  | PULSAR TEMPLATE VARIABLES
  |--------------------------------------------------------------------------
  */
  --transistion-time: 1s;
  --colorBodyBg: #f2f2f2;
  --colorSidebarBg: #fff;
  --colorSidebarCollapseBg: rgba(64,64,64,0.1);
  --colorSidebarItem: #212529;
  --colorSidebarItemHover: #0d6efd;
  --colorSidebarItemHoverBg: rgba(13,110,253,0.1);
  --colorSidebarItemActive: #000;
  --colorSidebarItemActiveBg: rgba(13,110,253,0.3);
  --colorHeaderBg: #fff;
  --colorMenuBarBg: #fff;
  --colorMenubarItemHover: #0d6efd;
  --colorMenubarItemHoverBg: rgba(13,110,253,0.1);
  --colorMenubarItemActive: #000;
  --colorMenubarItemActiveBg: rgba(13,110,253,0.3);
/*
  |---------------------------------------------------------
  | Froms
  |---------------------------------------------------------
  */
  --colorInputBg: #f1f6ff;
  --colorBorderInput: #dee2e6;
  --colorTextInput: #212529;
  --colorPlaceholderInput: rgba(33,37,41,0.5);
  --colorFocusInputBorder: rgba(13,110,253,0.55);
  --colorFocusInputShadowIn: rgba(13,110,253,0.2);
  --colorFocusInputShadowout: #a5c9fe;
  --colorFocusInputPlaceholder: rgba(33,37,41,0.3);
  --colorBgDisable: rgba(0,0,0,0.1);
  --colorDisableText: rgba(33,37,41,0.7);
  --colorDisablePlaceholder: rgba(33,37,41,0.3);
  --colorDisableBorder: rgba(33,37,41,0.2);
  --colorFormText: rgba(33,37,41,0.75);
  --colorFormTextPlain: rgba(33,37,41,0.75);
  --colorFileSelectorButtonBg: #0d6efd;
  --colorFileSelectorButtonHover: #024cb9;
  --colorFileSelectorButtonFocus: #012b6a;
}
[data-bs-theme=dark] {
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173,181,189,0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173,181,189,0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #72abfe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255,255,255,0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
/*
  |--------------------------------------------------------------------------
  | PULSAR TEMPLATE VARIABLES
  |--------------------------------------------------------------------------
  */
  --colorBodyBg: #111215;
  --colorSidebarBg: #212529;
  --colorSidebarCollapseBg: rgba(8,9,10,0.3);
  --colorSidebarItem: #adb5bd;
  --colorSidebarItemHover: #72abfe;
  --colorSidebarItemHoverBg: rgba(0,4,11,0.2);
  --colorSidebarItemActive: #fff;
  --colorSidebarItemActiveBg: rgba(13,110,253,0.3);
  --colorHeaderBg: #212529;
  --colorMenuBarBg: #212529;
  --colorMenubarItemHover: #72abfe;
  --colorMenubarItemHoverBg: rgba(0,4,11,0.2);
  --colorMenubarItemActive: #fff;
  --colorMenubarItemActiveBg: rgba(13,110,253,0.3);
}
/**
|-------------------------------------------------------------------------------
| Elimina estilos del navegador
|-------------------------------------------------------------------------------
| Reinicia los valores de estilos establecidos por defecto por el navegador
|
*/
body:after {
  border-radius: 0 0 0;
  right: 0;
  top: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  content: "UP | Above 4k";
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: fixed;
  text-align: center;
  width: 240px;
  z-index: 9999999999;
  color: #fff;
  background-color: #008b8b;
}
@media only screen and (max-width: 3841px) {
  body:after {
    content: "UHD | Ultra HD - 4k";
    background-color: #cd5c5c;
    color: #fff;
  }
}
@media only screen and (max-width: 2561px) {
  body:after {
    content: "QHD | Quad HD - 2k";
    background-color: #ffe4b5;
    color: #222;
  }
}
@media only screen and (max-width: 1921px) {
  body:after {
    content: "FHD | Ful HD - 1080p";
    background-color: #dda0dd;
    color: #fff;
  }
}
@media only screen and (max-width: 1601px) {
  body:after {
    content: "XL | Extra Large";
    background-color: #8fbc8f;
  }
}
@media only screen and (max-width: 1281px) {
  body:after {
    content: "HD | High Definition - 720p";
    background-color: #add8e6;
    color: #222;
  }
}
@media only screen and (max-width: 767px) {
  body:after {
    content: "MD | Medium";
    color: #fff;
    background-color: #daa520;
  }
}
@media only screen and (max-width: 501px) {
  body:after {
    content: "SM | Small";
    background-color: #6495ed;
  }
}
@media only screen and (max-width: 341px) {
  body:after {
    content: "XS | Extra Small";
    background-color: #ffa07a;
  }
}
html {
  height: 100%;
}
body {
  background-color: #f2f2f2;
  background-color: var(--colorBodyBg);
  max-height: 100%;
  overflow: hidden;
}
.name-block {
  min-width: 300px;
  padding: 0;
  margin: 0;
}
.name-block .name-tag {
  line-height: 0rem;
  margin-bottom: 0;
  -webkit-transition: font-size 0.2s linear, -webkit-transform 0.2s ease-in-out;
  transition: font-size 0.2s linear, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, font-size 0.2s linear;
  transition: transform 0.2s ease-in-out, font-size 0.2s linear, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: translate3d(0px, 12px, 0);
  transform: translate3d(0px, 12px, 0);
}
.name-block .id-tag {
  display: inline-block;
  -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, -webkit-transform 0.2s ease-in-out;
  opacity: 0.001;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
}
.name-block .id-tag * {
  font-size: 0.7rem;
}
.name-block .id-tag .btn {
  padding: 0 5px;
  border-radius: 4px;
}
.name-block .id-tag .btn-copy {
  width: 5.3em;
}
.name-block:hover .name-tag,
.name-block.active .name-tag {
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.name-block:hover .id-tag,
.name-block.active .id-tag {
  opacity: 1;
  -ms-filter: none;
  -webkit-filter: none;
          filter: none;
  -webkit-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}
.width-10 {
  width: 10px;
}
.width-20 {
  width: 20px;
}
.width-30 {
  width: 30px;
}
.width-40 {
  width: 40px;
}
.width-50 {
  width: 50px;
}
.width-60 {
  width: 60px;
}
.width-70 {
  width: 70px;
}
.width-80 {
  width: 80px;
}
.width-90 {
  width: 90px;
}
.width-100 {
  width: 100px;
}
.width-110 {
  width: 110px;
}
.width-120 {
  width: 120px;
}
.width-130 {
  width: 130px;
}
.width-140 {
  width: 140px;
}
.width-150 {
  width: 150px;
}
.width-160 {
  width: 160px;
}
.width-170 {
  width: 170px;
}
.width-180 {
  width: 180px;
}
.width-190 {
  width: 190px;
}
.width-200 {
  width: 200px;
}
.width-210 {
  width: 210px;
}
.width-220 {
  width: 220px;
}
.width-230 {
  width: 230px;
}
.width-240 {
  width: 240px;
}
.width-250 {
  width: 250px;
}
.width-260 {
  width: 260px;
}
.width-270 {
  width: 270px;
}
.width-280 {
  width: 280px;
}
.width-290 {
  width: 290px;
}
.width-300 {
  width: 300px;
}
.width-310 {
  width: 310px;
}
.width-320 {
  width: 320px;
}
.width-330 {
  width: 330px;
}
.width-340 {
  width: 340px;
}
.width-350 {
  width: 350px;
}
.width-360 {
  width: 360px;
}
.width-370 {
  width: 370px;
}
.width-380 {
  width: 380px;
}
.width-390 {
  width: 390px;
}
.width-400 {
  width: 400px;
}
.width-410 {
  width: 410px;
}
.width-420 {
  width: 420px;
}
.width-430 {
  width: 430px;
}
.width-440 {
  width: 440px;
}
.width-450 {
  width: 450px;
}
.width-460 {
  width: 460px;
}
.width-470 {
  width: 470px;
}
.width-480 {
  width: 480px;
}
.width-490 {
  width: 490px;
}
.width-500 {
  width: 500px;
}
.width-510 {
  width: 510px;
}
.width-520 {
  width: 520px;
}
.width-530 {
  width: 530px;
}
.width-540 {
  width: 540px;
}
.width-550 {
  width: 550px;
}
.width-560 {
  width: 560px;
}
.width-570 {
  width: 570px;
}
.width-580 {
  width: 580px;
}
.width-590 {
  width: 590px;
}
.width-600 {
  width: 600px;
}
.width-610 {
  width: 610px;
}
.width-620 {
  width: 620px;
}
.width-630 {
  width: 630px;
}
.width-640 {
  width: 640px;
}
.width-650 {
  width: 650px;
}
.width-660 {
  width: 660px;
}
.width-670 {
  width: 670px;
}
.width-680 {
  width: 680px;
}
.width-690 {
  width: 690px;
}
.width-700 {
  width: 700px;
}
.width-710 {
  width: 710px;
}
.width-720 {
  width: 720px;
}
.width-730 {
  width: 730px;
}
.width-740 {
  width: 740px;
}
.width-750 {
  width: 750px;
}
.width-760 {
  width: 760px;
}
.width-770 {
  width: 770px;
}
.width-780 {
  width: 780px;
}
.width-790 {
  width: 790px;
}
.width-800 {
  width: 800px;
}
.width-810 {
  width: 810px;
}
.width-820 {
  width: 820px;
}
.width-830 {
  width: 830px;
}
.width-840 {
  width: 840px;
}
.width-850 {
  width: 850px;
}
.width-860 {
  width: 860px;
}
.width-870 {
  width: 870px;
}
.width-880 {
  width: 880px;
}
.width-890 {
  width: 890px;
}
.width-900 {
  width: 900px;
}
.width-910 {
  width: 910px;
}
.width-920 {
  width: 920px;
}
.width-930 {
  width: 930px;
}
.width-940 {
  width: 940px;
}
.width-950 {
  width: 950px;
}
.width-960 {
  width: 960px;
}
.width-970 {
  width: 970px;
}
.width-980 {
  width: 980px;
}
.width-990 {
  width: 990px;
}
.width-1000 {
  width: 1000px;
}
.width-1010 {
  width: 1010px;
}
.width-1020 {
  width: 1020px;
}
.width-1030 {
  width: 1030px;
}
.width-1040 {
  width: 1040px;
}
.width-1050 {
  width: 1050px;
}
.width-1060 {
  width: 1060px;
}
.width-1070 {
  width: 1070px;
}
.width-1080 {
  width: 1080px;
}
.width-1090 {
  width: 1090px;
}
.width-1100 {
  width: 1100px;
}
.width-1110 {
  width: 1110px;
}
.width-1120 {
  width: 1120px;
}
.width-1130 {
  width: 1130px;
}
.width-1140 {
  width: 1140px;
}
.width-1150 {
  width: 1150px;
}
.width-1160 {
  width: 1160px;
}
.width-1170 {
  width: 1170px;
}
.width-1180 {
  width: 1180px;
}
.width-1190 {
  width: 1190px;
}
.width-1200 {
  width: 1200px;
}
.width-1210 {
  width: 1210px;
}
.width-1220 {
  width: 1220px;
}
.width-1230 {
  width: 1230px;
}
.width-1240 {
  width: 1240px;
}
.width-1250 {
  width: 1250px;
}
.width-1260 {
  width: 1260px;
}
.width-1270 {
  width: 1270px;
}
.width-1280 {
  width: 1280px;
}
.width-1290 {
  width: 1290px;
}
.width-1300 {
  width: 1300px;
}
.width-1310 {
  width: 1310px;
}
.width-1320 {
  width: 1320px;
}
.width-1330 {
  width: 1330px;
}
.width-1340 {
  width: 1340px;
}
.width-1350 {
  width: 1350px;
}
.width-1360 {
  width: 1360px;
}
.width-1370 {
  width: 1370px;
}
.width-1380 {
  width: 1380px;
}
.width-1390 {
  width: 1390px;
}
.width-1400 {
  width: 1400px;
}
.width-1410 {
  width: 1410px;
}
.width-1420 {
  width: 1420px;
}
.width-1430 {
  width: 1430px;
}
.width-1440 {
  width: 1440px;
}
.width-1450 {
  width: 1450px;
}
.width-1460 {
  width: 1460px;
}
.width-1470 {
  width: 1470px;
}
.width-1480 {
  width: 1480px;
}
.width-1490 {
  width: 1490px;
}
.width-1500 {
  width: 1500px;
}
.width-1510 {
  width: 1510px;
}
.width-1520 {
  width: 1520px;
}
.width-1530 {
  width: 1530px;
}
.width-1540 {
  width: 1540px;
}
.width-1550 {
  width: 1550px;
}
.width-1560 {
  width: 1560px;
}
.width-1570 {
  width: 1570px;
}
.width-1580 {
  width: 1580px;
}
.width-1590 {
  width: 1590px;
}
.width-1600 {
  width: 1600px;
}
.width-1610 {
  width: 1610px;
}
.width-1620 {
  width: 1620px;
}
.width-1630 {
  width: 1630px;
}
.width-1640 {
  width: 1640px;
}
.width-1650 {
  width: 1650px;
}
.width-1660 {
  width: 1660px;
}
.width-1670 {
  width: 1670px;
}
.width-1680 {
  width: 1680px;
}
.width-1690 {
  width: 1690px;
}
.width-1700 {
  width: 1700px;
}
.width-1710 {
  width: 1710px;
}
.width-1720 {
  width: 1720px;
}
.width-1730 {
  width: 1730px;
}
.width-1740 {
  width: 1740px;
}
.width-1750 {
  width: 1750px;
}
.width-1760 {
  width: 1760px;
}
.width-1770 {
  width: 1770px;
}
.width-1780 {
  width: 1780px;
}
.width-1790 {
  width: 1790px;
}
.width-1800 {
  width: 1800px;
}
.width-1810 {
  width: 1810px;
}
.width-1820 {
  width: 1820px;
}
.width-1830 {
  width: 1830px;
}
.width-1840 {
  width: 1840px;
}
.width-1850 {
  width: 1850px;
}
.width-1860 {
  width: 1860px;
}
.width-1870 {
  width: 1870px;
}
.width-1880 {
  width: 1880px;
}
.width-1890 {
  width: 1890px;
}
.width-1900 {
  width: 1900px;
}
.width-1910 {
  width: 1910px;
}
.width-1920 {
  width: 1920px;
}
