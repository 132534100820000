// // Importar Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';
// bootstrap-icons.scss
// @import 'node_modules/bootstrap-icons/font/bootstrap-icons.scss';


$bootstrap-icons-font: "bootstrap-icons" !default;
$bootstrap-icons-font-dir: "../fonts" !default;
$bootstrap-icons-font-file: "#{$bootstrap-icons-font-dir}/#{$bootstrap-icons-font}" !default;
$bootstrap-icons-font-hash: "24e3eb84d0bcaf83d77f904c78ac1f47" !default;
$bootstrap-icons-font-src: url("#{$bootstrap-icons-font-file}.woff2?#{$bootstrap-icons-font-hash}") format("woff2"),
                           url("#{$bootstrap-icons-font-file}.woff?#{$bootstrap-icons-font-hash}") format("woff") !default;

@font-face {
  font-display: block;
  font-family: $bootstrap-icons-font;
  src: $bootstrap-icons-font-src;
}